import {Action, createFeatureSelector, createReducer, createSelector, on} from '@ngrx/store';
import * as AppActions from './app-actions';
import {SongDto} from '../shared/models/song.dto';
import {LIBRARY_TYPE} from '../shared/enums/LIBRARY_TYPE';
import {PlaylistDto} from '../shared/models/playlist.dto';

export interface State {
  isBusy: boolean;
  libraryTypeSongsMap: Map<LIBRARY_TYPE, PlaylistDto>;
  userPlaylists: Map<string, PlaylistDto>;
  userLibrary: Map<string, PlaylistDto>;
  nowPlaying: { song: SongDto, playlist: PlaylistDto, isInitiatedByPlayer: boolean };
  pendingSongs: Map<string, SongDto>;
  lastUpdatedUserPlaylist: PlaylistDto;
  isSingleSongBusy: boolean;
  backgroundColor: string;
}

export const initialState: State = {
  isBusy: false,
  libraryTypeSongsMap: new Map<LIBRARY_TYPE, PlaylistDto>(),
  userPlaylists: new Map<string, PlaylistDto>(),
  userLibrary: new Map<string, PlaylistDto>(),
  nowPlaying: {song: new SongDto(), playlist: new PlaylistDto(), isInitiatedByPlayer: false},
  pendingSongs: new Map<string, SongDto>(),
  lastUpdatedUserPlaylist: null,
  isSingleSongBusy: false,
  backgroundColor: '',
};

const AppReducer = createReducer(
  initialState,

  on(AppActions.setSongsLibrary, (state, {libraryType, playlist}) => {
    state.libraryTypeSongsMap.set(libraryType, playlist);
    return {...state, libraryTypeSongsMap: new Map(state.libraryTypeSongsMap)};
  }),

  on(AppActions.setUserPlaylists, (state, {playlists}) => ({...state, userPlaylists: playlists})),

  on(AppActions.setUserLibrary, (state, {library}) => ({...state, userLibrary: library})),

  on(AppActions.playlistUpdated, (state, {playlist}) => {
    state.userPlaylists.set(playlist.id, playlist);
    return {...state, lastUpdatedUserPlaylist: playlist};
  }),

  on(AppActions.setNowPlaying, (state, {song, playlist, isInitiatedByPlayer}) => ({
    ...state,
    nowPlaying: {song, playlist, isInitiatedByPlayer}
  })),

  on(AppActions.updatePendingSongs, (state, {songs}) => ({...state, pendingSongs: songs})),

  on(AppActions.updatePendingSong, (state, {song, toDelete}) => {
    toDelete ? state.pendingSongs.delete(song.id) : state.pendingSongs.set(song.id, song);
    return {...state};
  }),

  on(AppActions.setSingleSongBusy, (state, {busy}) => ({...state, isSingleSongBusy: busy})),

  on(AppActions.updateBackgroundColor, (state, {backgroundColor}) => ({...state, backgroundColor})),

  on(AppActions.requestStarted, (state) => ({...state, isBusy: true})),

  on(AppActions.requestEnded, (state) => ({...state, isBusy: false})),
);

export function AppReducers(state: State | undefined, action: Action) {
  return AppReducer(state, action);
}

export const GetLibraries = (state: State) => state.libraryTypeSongsMap;

export const GetUserPlaylistsMap = (state: State) => state.userPlaylists;

export const GetUserPlaylistsList = (state: State) => Array.from(state.userPlaylists.values());

export const GetUserLibrary = (state: State) => state.userLibrary;

export const GetNowPlaying = (state: State) => state.nowPlaying;

export const GetPendingSongs = (state: State) => state.pendingSongs;

export const GetLastUpdatedUserPlaylist = (state: State) => state.lastUpdatedUserPlaylist;

export const IsSingleSongBusy = (state: State) => state.isSingleSongBusy;

export const GetBackgroundColor = (state: State) => state.backgroundColor;

export const IsBusy = (state: State) => state.isBusy;


export const selectAppState = createFeatureSelector<State>('KeePlaying');

export const getLibraries = () => createSelector(
  selectAppState,
  GetLibraries
);

export const getUserPlaylistsMap = () => createSelector(
  selectAppState,
  GetUserPlaylistsMap
);

export const getUserPlaylistList = () => createSelector(
  selectAppState,
  GetUserPlaylistsList
);

export const getUserLibrary = () => createSelector(
  selectAppState,
  GetUserLibrary
);

export const getNowPlaying = () => createSelector(
  selectAppState,
  GetNowPlaying
);

export const getPendingSongs = () => createSelector(
  selectAppState,
  GetPendingSongs
);

export const getLastUpdatedUserPlaylist = () => createSelector(
  selectAppState,
  GetLastUpdatedUserPlaylist
);

export const isSingleSongBusy = () => createSelector(
  selectAppState,
  IsSingleSongBusy
);

export const getBackgroundColor = () => createSelector(
  selectAppState,
  GetBackgroundColor
);

export const isBusy = () => createSelector(
  selectAppState,
  IsBusy
);
