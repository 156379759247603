<div class="side-menu__container">
  <div class="side-menu__logo">
    <img src="assets/images/logo.png">
  </div>
  <div class="side-menu__content">
    <a *ngFor="let item of menuItems" class="side-menu__item" [routerLink]="item.route"
       routerLinkActive="side-menu__selected" [routerLinkActiveOptions]="{exact: item.exact}">
      <div class="material-icons side-menu__icon">{{item.icon}}</div>
      <div class="side-menu__label">{{item.name}}</div>
      <div class="side-menu__active"></div>
    </a>
  </div>
  <div class="side-menu__apps">
    <a href="https://ply.gl/com.highlake.keeplaying" target="_blank">
      <img src="assets/images/google_play.png" class="side-menu__android">
    </a>
  </div>
</div>
