<div class="playlist-header__container" [ngStyle]="{'background': bgColor}">
  <div class="playlist-header__img">
    <img *ngIf="playlist" [src]="playlist?.img ? playlist?.img : 'assets/images/disc.svg'">
    <div *ngIf="!playlist" class="playlist-header__img-ph" [ngClass]="{'kp-loading-ph': loading}"></div>
  </div>
  <div class="playlist-header__text">
    <div class="playlist-header__title" [ngClass]="{'kp-loading-ph': loading}">{{playlist?.name}}</div>
    <div class="playlist-header__description" [ngClass]="{'kp-loading-ph': loading}">{{playlist?.artist}}</div>
  </div>
</div>
