import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NavComponent} from './nav/nav.component';
import {HttpClientModule} from '@angular/common/http';
import {RouterModule} from '@angular/router';
import {PlaylistOverlayComponent} from './playlist-overlay/playlist-overlay.component';
import {BackgroundComponent} from './background/background.component';
import {PlayingBarComponent} from './playing-bar/playing-bar.component';
import {DurationPipeModule} from '../shared/pipes/duration/duration-pipe.module';
import {AudioProgressBarComponent} from './playing-bar/audio-progress-bar/audio-progress-bar.component';
import {ButtonsBarComponent} from './playing-bar/buttons-bar/buttons-bar.component';
import {ProgressPipeModule} from '../shared/pipes/progress/progress-pipe.module';
import {MatButtonModule} from '@angular/material/button';
import {MatBadgeModule} from '@angular/material/badge';
import {MatIconModule} from '@angular/material/icon';
import {NavMenuComponent} from './menus/nav-menu/nav-menu.component';
import {YouTubePlayerModule} from '@angular/youtube-player';
import { PbVideoComponent } from './playing-bar/pb-video/pb-video.component';
import { PbCollapsedComponent } from './playing-bar/pb-collapsed/pb-collapsed.component';

@NgModule({
  declarations: [NavComponent, PlaylistOverlayComponent, BackgroundComponent, PlayingBarComponent, AudioProgressBarComponent, ButtonsBarComponent, NavMenuComponent, PbVideoComponent, PbCollapsedComponent],
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    DurationPipeModule,
    ProgressPipeModule,
    MatButtonModule,
    MatBadgeModule,
    MatIconModule,
    YouTubePlayerModule,
  ],
  exports: [NavComponent, PlaylistOverlayComponent, BackgroundComponent, PlayingBarComponent, NavMenuComponent]
})
export class CoreModule {
}
