import {Injectable} from '@angular/core';
import {SongDto} from '../../shared/models/song.dto';
import {PlaylistDto} from '../../shared/models/playlist.dto';

@Injectable({
  providedIn: 'root'
})
export class MusicService {

  private static readonly USER_PLAYLISTS_KEY = 'playlists';
  private static readonly RECENTLY_PLAYED_KEY = 'recently_played';
  private static readonly RECENT_SEEDS_KEY = 'recent_seeds';
  private static readonly LIBRARY_KEY = 'library';

  constructor() {
  }

  public static loadUserPlaylists() {
    return this.loadPlaylists(this.USER_PLAYLISTS_KEY);
  }

  public static loadUserLibrary(): Map<string, PlaylistDto> {
    return this.loadPlaylists(this.LIBRARY_KEY);
  }

  public static loadRecentlyPlayed(): PlaylistDto[] {
    return this.loadRecentItems(this.RECENTLY_PLAYED_KEY);
  }

  public static loadRecentSeeds(): string[] {
    return this.loadRecentItems(this.RECENT_SEEDS_KEY);
  }

  public static loadRecentItems(key: string): any[] {
    let result = [];
    try {
      const data = localStorage.getItem(key);
      result = data ? JSON.parse(data) : [];
    } catch (e) {
      localStorage.setItem(this.RECENTLY_PLAYED_KEY, JSON.stringify([]));
    }
    return result;
  }

  public static saveUserPlaylist(playlist: PlaylistDto, songs: SongDto[], toadd: boolean, toDelete?: boolean): PlaylistDto {
    const allPlaylists = this.loadUserPlaylists();
    let playlistToSave: PlaylistDto = allPlaylists.get(playlist.id) ? allPlaylists.get(playlist.id) : playlist;
    const playlistSongsMap: Map<string, SongDto> = this.convertSongsListToMap(playlistToSave.tracks);
    if (toadd) {
      songs.forEach(song => playlistSongsMap.set(song.id, song));
    } else {
      songs.forEach(song => playlistSongsMap.delete(song.id));
    }
    playlistToSave = new PlaylistDto(playlistToSave.id, playlistToSave.name, playlistToSave.artist, playlistToSave.img, Array.from(playlistSongsMap.values()));
    allPlaylists.set(playlistToSave.id, playlistToSave);
    localStorage.setItem(this.USER_PLAYLISTS_KEY, JSON.stringify(Array.from(allPlaylists)));
    return playlistToSave;
  }

  public static saveLibrary(playlist: PlaylistDto, toDelete?: boolean): Map<string, PlaylistDto> {
    const library: Map<string, PlaylistDto> = this.loadUserLibrary();
    if (toDelete) {
      library.delete(playlist.id);
    } else {
      library.set(playlist.id, playlist);
    }
    localStorage.setItem(this.LIBRARY_KEY, JSON.stringify(Array.from(library)));
    return library;
  }

  public static updateRecentlyPlayed(playlist: PlaylistDto) {
    const dataFromLocalStorage = localStorage.getItem(this.RECENTLY_PLAYED_KEY);
    const recentlyPlayed: PlaylistDto[] = dataFromLocalStorage ? JSON.parse(dataFromLocalStorage).filter(p => p.id !== playlist.id) : [];
    recentlyPlayed.unshift(playlist);
    localStorage.setItem(this.RECENTLY_PLAYED_KEY, JSON.stringify(recentlyPlayed.slice(0, 7)));
  }

  public static updateRecentSeeds(seed: string) {
    if (seed) {
      const dataFromLocalStorage = localStorage.getItem(this.RECENT_SEEDS_KEY);
      const seeds: string[] = dataFromLocalStorage ? JSON.parse(dataFromLocalStorage).filter(id => id !== seed) : [];
      seeds.unshift(seed);
      localStorage.setItem(this.RECENT_SEEDS_KEY, JSON.stringify(seeds.slice(0, 5)));
    }
  }

  private static loadPlaylists(key: string) {
    const result = new Map<string, PlaylistDto>();
    const playlistsData: PlaylistDto[] = this.loadData(key, (data => new Map<string, PlaylistDto>(data)));
    if (playlistsData) {
      playlistsData.forEach(playlist => result.set(playlist.id, playlist));
    }
    return result;
  }


  private static loadData(key: string, createObjectMethod?) {
    let result: any = null;
    const dataFromLocalStorage = localStorage.getItem(key);
    if (dataFromLocalStorage) {
      try {
        const parsedData = JSON.parse(dataFromLocalStorage);
        if (createObjectMethod) {
          result = createObjectMethod(parsedData);
        } else {
          result = parsedData;
        }
      } catch (e) {
      }
    }
    return result;
  }

  private static convertSongsListToMap(songs: SongDto[]): Map<string, SongDto> {
    const result = new Map<string, SongDto>();
    songs.forEach(song => result.set(song.id, song));
    return result;
  }

}
