import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KpContentComponent } from './kp-content.component';

@NgModule({
  declarations: [KpContentComponent],
  exports: [
    KpContentComponent
  ],
  imports: [
    CommonModule
  ]
})
export class KpContentModule { }
