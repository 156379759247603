import {Store} from '@ngrx/store';
import {Injectable} from '@angular/core';
import {getLoadedPlaylists, isBusy} from './playlist-reducers';

@Injectable()
export class PlaylistSelector {
  constructor(private store: Store) {
  }

  isBusy() {
    return this.store.select(isBusy());
  }

  getLoadedPlaylists() {
    return this.store.select(getLoadedPlaylists());
  }
}
