import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {PlaylistDto} from '../../shared/models/playlist.dto';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PlaylistService {

  private readonly PLAYLISTS_URL: string = `${environment.serverUrl}media?id=`;


  constructor(private http: HttpClient) {
  }

  fetchPlaylistById(playlistId: string): Observable<PlaylistDto> {
    return this.http.get(`${this.PLAYLISTS_URL}${playlistId}`).pipe(map(res => res as PlaylistDto));
  }
}
