import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProgressPipe} from './pipes/progress/progress.pipe';
import {FilterPipe} from './pipes/filter/filter.pipe';

@NgModule({
  declarations: [],
  exports: [],
  imports: [
    CommonModule
  ]
})
export class SharedModule {
}
