import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {PlaylistDto} from '../../shared/models/playlist.dto';
import {map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {SearchResult} from '../../shared/models/SearchResult';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  private readonly SUGGESTIONS_URL: string = `${environment.serverUrl}search/suggestions?term=`;
  private readonly SEARCH_URL: string = `${environment.serverUrl}search?term=`;


  constructor(private http: HttpClient) {
  }

  fetchSuggestions(searchTerm): Observable<string[]> {
    return this.http.get(this.SUGGESTIONS_URL + searchTerm).pipe(map((res: string[]) => res));
  }

  fetchSearchResults(searchTerm): Observable<SearchResult[]> {
    return this.http.get(this.SEARCH_URL + searchTerm).pipe(map((res: SearchResult[]) => res));
  }
}
