import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PlaylistComponent} from './playlist-main/playlist.component';
import {MediaItemModule} from '../shared/components/media-item/media-item.module';
import {PlaylistItemComponent} from './playlist-item/playlist-item.component';
import {ActionMenuModule} from '../shared/action-menu/action-menu.module';
import {KpContentModule} from '../shared/wrappers/kp-content/kp-content.module';
import {PlaylistSelector} from './store/playlist-selectors';
import {PlaylistDispatcher} from './store/playlist-dispatchers';
import {PlaylistHeaderComponent} from './playlist-header/playlist-header.component';
import {SpinnerModule} from '../shared/spinner/spinner.module';
import {IconModule} from '../shared/components/icon/icon.module';
import {MatIconModule} from '@angular/material/icon';
import {ClickOutsideDirectiveModule} from '../shared/directives/click-outside/click-outside-directive.module';

@NgModule({
  declarations: [PlaylistComponent, PlaylistItemComponent, PlaylistHeaderComponent],
  exports: [PlaylistItemComponent],
  imports: [
    CommonModule,
    MediaItemModule,
    ActionMenuModule,
    KpContentModule,
    SpinnerModule,
    IconModule,
    MatIconModule,
  ],
  providers: [
    PlaylistSelector,
    PlaylistDispatcher
  ]
})
export class PlaylistModule {
}
