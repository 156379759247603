import {Injectable} from '@angular/core';

export class SideMenuItem {
  constructor(public name: string, public icon: string, public route: string, public exact?: boolean) {
  }
}

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  public static readonly menuItems = [
    new SideMenuItem('Home', 'equalizer', '/', true),
    new SideMenuItem('Search', 'search', 'search'),
    new SideMenuItem('My Library', 'library_music', 'library'),
  ];

  constructor() {
  }
}
