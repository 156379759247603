<div class="playlist-item__container" [ngClass]="{'playlist-item__selected': selected}" (click)="onClicked()">
  <div class="playlist-item__index">{{index}}</div>
  <div class="playlist-item__info">
    <div class="playlist-item__title">{{song.name}}</div>
    <div class="playlist-item__artist">{{song.artist}}</div>
  </div>
  <div *ngIf="menuItems.length" class="playlist-item__actions">
    <app-action-menu [id]="song.id" [items]="menuItems"></app-action-menu>
  </div>
</div>
