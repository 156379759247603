import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {Actions, Effect, ofType} from '@ngrx/effects';
import * as AppActions from './app-actions';
import {catchError, map, mergeMap, switchMap} from 'rxjs/operators';
import {AppDispatcher} from './app-dispatchers';
import {SongsApiService} from './services/songs-api.service';
import {of} from 'rxjs';
import {MusicService} from './services/music.service';
import {PlaylistDto} from '../shared/models/playlist.dto';

@Injectable()
export class AppEffects {

  constructor(private store: Store,
              private actions$: Actions,
              private songsApiService: SongsApiService,
              private appDispatcher: AppDispatcher) {
  }

  @Effect()
  fetchSongsLibrary$ = this.actions$.pipe(ofType(AppActions.fetchSongsLibrary))
    .pipe(mergeMap(requestData => {
      this.appDispatcher.requestStarted();
      return this.songsApiService.fetchSongsByLibraryType(requestData.libraryType)
        .pipe(map(res => {
            this.appDispatcher.setSongsLibrary(requestData.libraryType, res);
            return AppActions.requestEnded({});
          }),
          catchError(error => {
            return of(AppActions.requestEnded({}));
          })
        );
    }));

  @Effect()
  fetchUserPlaylists$ = this.actions$.pipe(ofType(AppActions.fetchAllUserPlaylists))
    .pipe(map(requestData => {
      this.appDispatcher.setUserPlaylists(MusicService.loadUserPlaylists());
      return AppActions.requestEnded({});
    }));

  @Effect()
  updatePlaylist$ = this.actions$.pipe(ofType(AppActions.updatePlaylist))
    .pipe(map(requestData => {
      const playlist: PlaylistDto = MusicService.saveUserPlaylist(requestData.playlist, requestData.songs, requestData.toAdd);
      this.appDispatcher.playlistUpdated(playlist);
      return AppActions.requestEnded({});
    }));

  @Effect()
  fetchUserLibrary$ = this.actions$.pipe(ofType(AppActions.fetchUserLibrary))
    .pipe(map(requestData => {
      this.appDispatcher.setUserLibrary(MusicService.loadUserLibrary());
      return AppActions.requestEnded({});
    }));

  @Effect()
  updateUserLibrary$ = this.actions$.pipe(ofType(AppActions.updateUserLibrary))
    .pipe(map(requestData => {
      const playlistToSave: PlaylistDto = {...requestData.playlist, tracks: []};
      const library: Map<string, PlaylistDto> = MusicService.saveLibrary(playlistToSave, requestData.toDelete);
      this.appDispatcher.setUserLibrary(library);
      return AppActions.requestEnded({});
    }));

  @Effect()
  updateRecentSeeds$ = this.actions$.pipe(ofType(AppActions.updateRecentSeeds))
    .pipe(map(requestData => {
      MusicService.updateRecentSeeds(requestData.seed);
      return AppActions.done({});
    }));

  @Effect()
  fetchSingleSongToPlay$ = this.actions$.pipe(ofType(AppActions.fetchSingleSongToPlay))
    .pipe(switchMap(requestData => {
      this.appDispatcher.setSingleSongBusy(true);
      return this.songsApiService.fetchSingSongToPlay(requestData.songId)
        .pipe(map(res => {
            this.appDispatcher.setSingleSongBusy(false);
            this.appDispatcher.setNowPlaying(res.tracks[0], res);
            return AppActions.requestEnded({});
          }),
          catchError(error => {
            return of(AppActions.requestEnded({}));
          })
        );
    }));
}
