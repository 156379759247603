import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {Actions, Effect, ofType} from '@ngrx/effects';
import * as PlaylistActions from './playlist-actions';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {PlaylistDispatcher} from './playlist-dispatchers';
import {PlaylistService} from '../services/playlist.service';
import {MusicService} from '../../ngrx/services/music.service';


@Injectable()
export class PlaylistEffects {

  constructor(private store: Store, private actions$: Actions,
              private plDispatcher: PlaylistDispatcher,
              private playlistService: PlaylistService) {
  }

  @Effect()
  fetchCategories$ = this.actions$.pipe(ofType(PlaylistActions.fetchPlaylist))
    .pipe(mergeMap(requestData => {
      this.plDispatcher.start();
      return this.playlistService.fetchPlaylistById(requestData.playlistId)
        .pipe(map(res => {
            this.plDispatcher.addLoadedPlaylists([res]);
            MusicService.updateRecentlyPlayed(res);
            return PlaylistActions.done({});
          }),
          catchError(error => {
            return of(PlaylistActions.done({}));
          })
        );
    }));

}
