import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {LIBRARY_TYPE} from '../../shared/enums/LIBRARY_TYPE';
import {SongDto} from '../../shared/models/song.dto';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {EXTERNAL_SOURCE} from '../../shared/enums/EXTERNAL_SOURCE';
import {PlaylistDto} from '../../shared/models/playlist.dto';

@Injectable({
  providedIn: 'root'
})
export class SongsApiService {

  private readonly SONGS_URL: string = `${environment.serverUrl}songs?libraryType=`;
  private readonly EXTERNAL_CONTENT_URL: string = `${environment.serverUrl}media?id=`;

  constructor(private http: HttpClient) {
  }

  fetchSongsByLibraryType(libraryType: LIBRARY_TYPE): Observable<PlaylistDto> {
    return this.http.get(`${this.SONGS_URL}${libraryType}`)
      .pipe(map(res => new PlaylistDto(libraryType.toString(), libraryType.toString(), this.fixSongs(res))));
  }

  fetchSingSongToPlay(id: string): Observable<PlaylistDto> {
    return this.http.get(this.EXTERNAL_CONTENT_URL + id).pipe(map(res => res as PlaylistDto));
  }

  private fixSongs(res: any): SongDto[] {
    let result: SongDto[] = [];
    if (res) {
      result = res.map(item => new SongDto(item.id, item.name, item.artist, item.img, item.lengthSeconds,
        item.backups, item.category, item.seed));
    }
    return result;
  }
}
