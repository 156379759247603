import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
// @ts-ignore
import FastAverageColor from 'fast-average-color';
import {PlaylistDto} from '../../shared/models/playlist.dto';
import {AppDispatcher} from '../../ngrx/app-dispatchers';

@Component({
  selector: 'app-playlist-header',
  templateUrl: './playlist-header.component.html',
  styleUrls: ['./playlist-header.component.scss']
})
export class PlaylistHeaderComponent implements OnInit, OnChanges {

  @Input() playlist: PlaylistDto;
  @Input() loading: boolean;
  public bgColor = 'rgba(255, 255, 255, 0.1)';

  constructor(public appDispatcher: AppDispatcher) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.loading && this.playlist && this.playlist.img) {
      const img = document.createElement('img');
      img.crossOrigin = '';
      img.src = this.playlist.img;
      const fac = new FastAverageColor();
      fac.getColorAsync(img).then(c => {
        this.bgColor = `linear-gradient(0deg, rgba(36,30,66,0.0) 0%, rgba(${c.value[0]}, ${c.value[1]} ,${c.value[2]} ,0.9) 80%)`;
        this.appDispatcher.updateBackgroundColor(this.bgColor);
      }).catch(e => console.log(e));
    }
  }

}
