import {Component, OnInit} from '@angular/core';
import {AppDispatcher} from './ngrx/app-dispatchers';
import {AppSelector} from './ngrx/app-selectors';
import {SongDto} from './shared/models/song.dto';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  public showPlayingBar: boolean;
  public pendingSongsMap: Map<string, SongDto>;

  constructor(private appSelector: AppSelector, private appDispatcher: AppDispatcher) {
  }

  ngOnInit(): void {
    this.appDispatcher.fetchUserPlaylists();
    this.appDispatcher.fetchUserLibrary();
    this.getNowPlaying();
    this.getPendingSongs();
  }


  getNowPlaying() {
    this.appSelector.getNowPlaying().subscribe(nowPlaying => this.showPlayingBar = nowPlaying.playlist.tracks.length > 0);
  }

  getPendingSongs() {
    this.appSelector.getPendingSongs().subscribe(songsMap => this.pendingSongsMap = songsMap);
  }

}
