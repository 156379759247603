import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {CoreModule} from './core/core.module';
import {SharedModule} from './shared/shared.module';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import {PlaylistModule} from './playlist/playlist.module';
import {HomepageModule} from './homepage/homepage.module';
import {StoreModule} from '@ngrx/store';
import {AppReducers} from './ngrx/app-reducers';
import {AppSelector} from './ngrx/app-selectors';
import {AppDispatcher} from './ngrx/app-dispatchers';
import {EffectsModule} from '@ngrx/effects';
import {AppEffects} from './ngrx/app-effects';
import {SideMenuModule} from './core/menus/side-menu/side-menu.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatDialogModule} from '@angular/material/dialog';
import {PlaylistDialogModule} from './shared/dialogs/playlist-dialog/playlist-dialog.module';
import {HomepageReducers} from './homepage/store/homepage-reducers';
import {HomepageEffects} from './homepage/store/homepage-effects';
import {PlaylistReducers} from './playlist/store/playlist-reducers';
import {PlaylistEffects} from './playlist/store/playlist-effects';
import {SearchModule} from './search/search.module';
import {SearchReducers} from './search/store/search-reducers';
import {SearchEffects} from './search/store/search-effects';
import {YouTubePlayerModule} from '@angular/youtube-player';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    SharedModule,
    PlaylistModule,
    HomepageModule,
    StoreModule.forRoot({KeePlaying: AppReducers}),
    EffectsModule.forRoot([AppEffects]),
    StoreModule.forFeature('KP_Homepage', HomepageReducers),
    StoreModule.forFeature('KP_Playlist', PlaylistReducers),
    StoreModule.forFeature('KP_Search', SearchReducers),
    EffectsModule.forFeature([HomepageEffects]),
    EffectsModule.forFeature([PlaylistEffects]),
    EffectsModule.forFeature([SearchEffects]),
    SideMenuModule,
    BrowserAnimationsModule,
    MatDialogModule,
    PlaylistDialogModule,
    SearchModule,
    YouTubePlayerModule

  ],
  providers: [
    AppSelector,
    AppDispatcher,
    {provide: LocationStrategy, useClass: HashLocationStrategy}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
