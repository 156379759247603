import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KpDialogComponent } from './kp-dialog.component';

@NgModule({
    declarations: [KpDialogComponent],
    exports: [
        KpDialogComponent
    ],
    imports: [
        CommonModule
    ]
})
export class KpDialogModule { }
