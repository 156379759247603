import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {SongDto} from '../../shared/models/song.dto';
import {interval, Subscription, timer} from 'rxjs';
import {AppSelector} from '../../ngrx/app-selectors';
import {AppDispatcher} from '../../ngrx/app-dispatchers';
import {PlaylistDto} from '../../shared/models/playlist.dto';
import {NowPlaying} from '../../shared/models/NowPlaying';
import {LocationStrategy} from '@angular/common';

@Component({
  selector: 'app-playing-bar',
  templateUrl: './playing-bar.component.html',
  styleUrls: ['./playing-bar.component.scss']
})
export class PlayingBarComponent implements OnInit, OnDestroy {

  public readonly playerVars: YT.PlayerVars = {
    rel: 0, autohide: 1, controls: 0, fs: 0, playsinline: 1, origin: 'https://www.youtube.com',
    modestbranding: 1, enablejsapi: 1,
  };
  public shuffledList: SongDto[] = [];
  public selectedSong: SongDto;
  public selectedSongDuration: number;
  public selectedSongProgress = 0;
  public player: YT.Player;
  public status;
  public shuffleOn: boolean;
  public repeat: boolean;
  public interval: Subscription;
  public onLoadTimer: Subscription;
  public isSingleSongBusy: boolean;
  public fullScreen: boolean;
  public playlist: PlaylistDto;
  public bgColor: string;
  private subs: Subscription[] = [];

  constructor(private appSelector: AppSelector, private appDispatcher: AppDispatcher, private location: LocationStrategy) {
  }

  ngOnInit() {
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    document.body.appendChild(tag);
    this.getNowPlaying();
    this.getSingleSongToPlayBusy();
    this.initFirstTrackDataOnLoad();
    this.getLastUpdatedUserPlaylist();
    this.getBackgroundColor();
  }

  getNowPlaying() {
    this.subs.push(this.appSelector.getNowPlaying().subscribe(nowPlaying => this.onNowPlayingUpdated(nowPlaying)));
  }

  getSingleSongToPlayBusy() {
    this.subs.push(this.appSelector.isSingleSongBusy().subscribe(singleSongBusy => this.onSingSongToPlayBusyUpdated(singleSongBusy)));
  }

  getLastUpdatedUserPlaylist() {
    this.subs.push(this.appSelector.getLastUpdatedUserPlaylist().subscribe(playlist => this.onUserPlaylistUpdated(playlist)));
  }

  getBackgroundColor() {
    this.subs.push(this.appSelector.getBackgroundColor().subscribe(bgColor => this.bgColor = bgColor));
  }

  initFirstTrackDataOnLoad() {
    this.onLoadTimer = timer(1000, 1000).subscribe(() => {
      this.selectedSongDuration = this.getCurrentTrackDuration();
      if (this.onLoadTimer && this.selectedSongDuration > 0) {
        this.onLoadTimer.unsubscribe();
      }
    });
  }

  onNowPlayingUpdated(nowPlaying: NowPlaying) {
    this.isSingleSongBusy = false;
    if (!nowPlaying.isInitiatedByPlayer) {
      if (!this.playlist || this.playlist.id !== nowPlaying.playlist.id) {
        this.playlist = nowPlaying.playlist;
        this.shuffle();
      }
      if (this.player && nowPlaying.song.id) {
        this.onSongClicked(nowPlaying.song, true, false);
      }
    }
    this.appDispatcher.updateRecentSeeds(nowPlaying.song.seed);
  }

  onSingSongToPlayBusyUpdated(singleSongBusy: boolean) {
    this.isSingleSongBusy = singleSongBusy;
    if (this.isSingleSongBusy) {
      this.pauseVideo();
    }
  }

  onUserPlaylistUpdated(playlist: PlaylistDto) {
    if (playlist && this.playlist && playlist.id === this.playlist.id) {
      this.playlist = playlist;
    }
  }

  savePlayer(event) {
    this.player = event.target;
  }

  onStateChange(event) {
    this.status = event.data;
    switch (this.status) {
      case YT.PlayerState.ENDED:
        this.onSongEnded();
        break;
      case YT.PlayerState.PLAYING:
        this.startTimer();
        this.selectedSongDuration = this.getCurrentTrackDuration();
        break;
      case YT.PlayerState.PAUSED:
        this.clearTimer(false);
        break;

    }
  }

  onSongClicked(song: SongDto, tryToShuffleIfOn = false, updateNowPlaying = true) {
    this.clearTimer();
    this.selectedSong = song;
    this.player.loadVideoById(this.selectedSong.id);
    if (tryToShuffleIfOn && this.shuffleOn) {
      this.shuffle();
    }
    if (updateNowPlaying) {
      this.appDispatcher.setNowPlaying(this.selectedSong, this.playlist, true);
    }
  }

  onSongEnded() {
    const nextSongIndex = this.getNextSongIndex();
    this.onSongClicked(nextSongIndex);
  }

  onShuffleClicked() {
    this.shuffleOn = !this.shuffleOn;
    if (this.shuffleOn) {
      this.shuffle();
    }
  }

  onRepeatClicked() {
    this.repeat = !this.repeat;
  }

  getNextSongIndex(): SongDto {
    const playlist = this.shuffleOn ? this.shuffledList : this.playlist.tracks;
    const index = playlist.indexOf(this.selectedSong);
    let songIndex = 0;
    if (index >= 0 && index + 1 < playlist.length) {
      songIndex = index + 1;
    }
    return playlist[songIndex];
  }

  playPauseSong() {
    if (this.status !== YT.PlayerState.BUFFERING) {
      if (this.status === YT.PlayerState.PLAYING) {
        this.pauseVideo();
      } else {
        this.playVideo();
      }
    }
  }

  playVideo() {
    if (this.player) {
      this.player.playVideo();
    }
  }

  pauseVideo() {
    if (this.player) {
      this.player.pauseVideo();
    }
  }

  nextPreviousVideo(nextVideo: boolean) {
    const playlist = this.shuffleOn ? this.shuffledList : this.playlist.tracks;
    const currentIndex: number = playlist.indexOf(this.selectedSong);
    const playlistLength = playlist.length;
    let indexToPlay: number = nextVideo ? currentIndex + 1 : currentIndex - 1;
    if (indexToPlay < 0) {
      indexToPlay = playlistLength - 1;
    } else if (indexToPlay >= playlistLength) {
      indexToPlay = 0;
    }
    this.onSongClicked(playlist[indexToPlay]);
  }

  shuffle() {
    const result: SongDto[] = [...this.playlist.tracks];
    for (let i = 0; i < result.length; i++) {
      const randomIndex = Math.floor(Math.random() * (i + 1));
      const savedItem = result[i];
      result[i] = result[randomIndex];
      result[randomIndex] = savedItem;
    }
    this.shuffledList = result;
  }

  getCurrentTrackDuration(): number {
    return this.player ? this.player.getDuration() : 0;
  }

  onProgressBarClicked(event) {
    const percent = event.offsetX / event.target.offsetWidth;
    const time = percent * this.selectedSongDuration;
    if (time) {
      this.selectedSongProgress = time;
      this.player.seekTo(time, true);
      this.player.playVideo();
    }
  }

  startTimer() {
    this.clearTimer();
    this.selectedSongProgress = this.player ? this.player.getCurrentTime() : 0;
    this.interval = interval(1000).subscribe(() => {
      this.selectedSongProgress++;
      if (this.selectedSongProgress >= this.selectedSongDuration) {
        this.clearTimer();
      }
    });
  }

  clearTimer(cleanProgress = true) {
    if (this.interval) {
      this.interval.unsubscribe();
      if (cleanProgress) {
        this.selectedSongProgress = 0;
      }
    }
  }

  openFullScreen() {
    if (window.innerWidth <= 960 && !this.fullScreen) {
      this.fullScreen = true;
      history.pushState(null, null, window.location.href);
    }
  }

  onError(e) {
    const fvd: string = e?.target?.playerInfo?.videoData?.video_id;
    const backups: string[] = this.selectedSong.backups;
    const fvdIndex: number = backups.indexOf(fvd);

    if (fvd && backups.length && (fvd === this.selectedSong.id || fvdIndex < backups.length - 1)) {
      this.player.loadVideoById(backups[fvdIndex + 1]);
    } else {
      if (this.playlist.tracks.length > 1) {
        this.nextPreviousVideo(true);
      }
    }
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    this.fullScreen = false;
  }

  ngOnDestroy(): void {
    if (this.subs) {
      this.subs.forEach(sub => sub.unsubscribe());
    }
    if (this.interval) {
      this.interval.unsubscribe();
    }
    if (this.onLoadTimer) {
      this.onLoadTimer.unsubscribe();
    }
  }

}
