import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MediaItemComponent } from './media-item.component';
import {MatIconModule} from '@angular/material/icon';

@NgModule({
    declarations: [MediaItemComponent],
    exports: [
        MediaItemComponent
    ],
    imports: [
        CommonModule,
        MatIconModule
    ]
})
export class MediaItemModule { }
