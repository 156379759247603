import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SongDto} from '../../models/song.dto';

@Component({
  selector: 'app-media-item',
  templateUrl: './media-item.component.html',
  styleUrls: ['./media-item.component.scss']
})
export class MediaItemComponent implements OnInit {

  @Input() title: string;
  @Input() subTitle: string;
  @Input() img: string;

  constructor() {
  }

  ngOnInit() {
  }

}
