import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActionMenuComponent } from './action-menu.component';
import {ClickOutsideDirectiveModule} from '../directives/click-outside/click-outside-directive.module';

@NgModule({
    declarations: [ActionMenuComponent],
    exports: [
        ActionMenuComponent
    ],
  imports: [
    CommonModule,
    ClickOutsideDirectiveModule
  ]
})
export class ActionMenuModule { }
