import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import * as SearchActions from './search-actions';


@Injectable()
export class SearchDispatcher {
  constructor(private store: Store) {
  }

  fetchSearchResults(searchTerm) {
    this.store.dispatch(SearchActions.fetchSearchResults({searchTerm}));
  }

  updateSearchResults(searchResults) {
    this.store.dispatch(SearchActions.updateSearchResults({searchResults}));
  }

  start() {
    this.store.dispatch(SearchActions.start({}));
  }

  done() {
    this.store.dispatch(SearchActions.done({}));
  }
}
