import {Component, Input, OnInit} from '@angular/core';
import {FeaturedContentDto} from '../../shared/models/featured-content.dto';

@Component({
  selector: 'app-homepage-strip',
  templateUrl: './homepage-strip.component.html',
  styleUrls: ['./homepage-strip.component.scss']
})
export class HomepageStripComponent implements OnInit {

  @Input() featuredContent: FeaturedContentDto;

  constructor() {
  }

  ngOnInit(): void {
  }

}
