<div class="playlist__container">
  <app-playlist-header [playlist]="loadedPlaylist" [loading]="busy"></app-playlist-header>

  <app-spinner *ngIf="busy" [showText]="true"></app-spinner>

  <app-kp-content *ngIf="loadedPlaylist">
    <div class="playlist__actions">
      <mat-icon class="playlist__play" (click)="playPlaylist()">play_circle_filled</mat-icon>
      <mat-icon *ngIf="!isUserPlaylist" class="playlist__like" [ngClass]="{'playlist__liked': isLiked}"
                (click)="onLike()">{{isLiked ? 'star' : 'star_border'}}</mat-icon>
      <mat-icon class="playlist__add" (click)="addPlaylistSongs()">playlist_add</mat-icon>
    </div>
    <div class="playlist__tracks">
      <div *ngFor="let song of loadedPlaylist.tracks; let i = index" class="playlist__track">
        <app-playlist-item [song]="song" [index]="i + 1" [selected]="song.id === nowPlayingSongId"
                           [editable]="isUserPlaylist" (onClick)="onSongClicked(song)" (onRemove)="onRemove(song)"></app-playlist-item>
      </div>
    </div>
  </app-kp-content>

</div>
