import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {interval, Subscription} from 'rxjs';


@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit, OnDestroy {

  public readonly STEPS = ['Reading Playlist', 'Converting Songs', 'Processing', 'Finish Processing'];
  @Input() public showText: boolean;
  public counter = 0;
  public text = this.STEPS[0];
  public dots: number[] = new Array(12);
  public interval: Subscription;


  constructor() {
  }

  ngOnInit() {
    this.startTimer();
  }

  startTimer() {
    this.interval = interval(1000).subscribe(() => {
      if (this.counter < 3) {
        this.text = this.STEPS[0];
      } else if (this.counter < 6) {
        this.text = this.STEPS[1];
      } else if (this.counter < 9) {
        this.text = this.STEPS[2];
      } else {
        this.text = this.STEPS[3];
      }
      this.counter++;
    });
  }

  ngOnDestroy(): void {
    this.text = this.STEPS[3];
    if (this.interval) {
      this.interval.unsubscribe();
    }
  }


}
