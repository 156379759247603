import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-background',
  templateUrl: './background.component.html',
  styleUrls: ['./background.component.scss']
})
export class BackgroundComponent implements OnInit {

  public items: number[] = new Array(10);

  constructor() {
  }

  ngOnInit() {
  }

}
