import {Component, Input, OnInit} from '@angular/core';
import {SongDto} from '../../../shared/models/song.dto';

@Component({
  selector: 'app-pb-video',
  templateUrl: './pb-video.component.html',
  styleUrls: ['./pb-video.component.scss']
})
export class PbVideoComponent implements OnInit {

  @Input() song: SongDto;
  @Input() img: string;
  @Input() playing: boolean;
  @Input() wideView: boolean;

  constructor() {
  }

  ngOnInit(): void {
  }

}
