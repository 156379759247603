export class ActionMenuItem {

  public label: string;
  public action: any;

  constructor(label: string, action: any) {
    this.label = label;
    this.action = action;
  }
}
