import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-audio-progress-bar',
  templateUrl: './audio-progress-bar.component.html',
  styleUrls: ['./audio-progress-bar.component.scss']
})
export class AudioProgressBarComponent implements OnInit {

  @Input() value: number;
  @Input() total: number;
  @Input() smooth: boolean;
  @Output() onClick: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit() {
  }

  onProgressBarClicked(event) {
    this.onClick.emit(event);
  }

}
