<div class="playing-bar__container" [ngClass]="{'playing-bar__full-screen':fullScreen}"
     [ngStyle]="{'background': fullScreen ? bgColor : ''}" (click)="openFullScreen()">
  <app-pb-video class="playing-bar__song" [song]="selectedSong" [wideView]="fullScreen" [img]="playlist.img"
                [playing]="player && status === 1">
    <youtube-player [playerVars]="playerVars" [videoId]="'dQw4w9WgXcQ'" (ready)="savePlayer($event)"
                    (stateChange)="onStateChange($event)" (error)="onError($event)" [width]="60" [height]="60"></youtube-player>
  </app-pb-video>
  <div class="playing-bar__player" [ngClass]="{'playing-bar__hidden': !fullScreen}">
    <ng-container *ngTemplateOutlet="playerButtons; context:{ wide: fullScreen}"></ng-container>
    <app-audio-progress-bar [value]="selectedSongProgress" [total]="selectedSongDuration"
                            [smooth]="this.status === 1" (onClick)="onProgressBarClicked($event)"
                            class="playing-bar__progress"></app-audio-progress-bar>
  </div>
  <div class="playing-bar__playlist"></div>
  <div *ngIf="!fullScreen" class="playing-bar__minimal">
    <ng-container *ngTemplateOutlet="playerButtons; context:{ collapsed: true}"></ng-container>
  </div>
</div>

<div class="playing-bar__canvas" [ngClass]="{'playing-bar__collapsed':!fullScreen}"></div>

<ng-template #playerButtons let-collapsed="collapsed" let-wide="wide">
  <app-buttons-bar [playing]="player && status === 1" [shuffle]="shuffleOn" [repeat]="repeat" [wide]="wide"
                   [buffering]="status === 3" [forceBuffering]="isSingleSongBusy" [collapsed]="collapsed"
                   (onPlayPause)="playPauseSong();" (onNextPrev)="nextPreviousVideo($event)"
                   (onShuffle)="onShuffleClicked()" (onRepeat)="onRepeatClicked()"
                   class="playing-bar__controls"></app-buttons-bar>
</ng-template>
