import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {

  public readonly START_TIME: string = '0:00';

  transform(value: number): any {
    let result: string = this.START_TIME;
    if (value) {
      let hours: string | number = Math.floor(value / 3600);
      let minutes: string | number = Math.floor((value - (hours * 3600)) / 60);
      let seconds: string | number = Math.floor(value - (hours * 3600) - (minutes * 60));

      hours = hours >= 10 ? `:${hours.toFixed()}` : hours > 0 ? `0${hours.toFixed()}:` : '';
      minutes = minutes >= 10 ? minutes.toFixed() : hours !== '' ? '0' + minutes.toFixed() : minutes.toFixed();
      seconds = seconds >= 10 ? seconds.toFixed() : '0' + seconds.toFixed();
      result = `${hours}${minutes}:${seconds}`;
    }
    return result;
  }

}
