<form [formGroup]="form" class="autocomplete__container">
  <mat-icon class="autocomplete__icon">search</mat-icon>
  <mat-form-field appearance="none">
    <input type="text"
           [placeholder]="placeholder"
           matInput
           formControlName="input"
           (keyup.enter)="onInputSubmit($event.target.value)"
           [matAutocomplete]="auto">
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onInputSubmit($event.option.value)">
      <mat-option *ngFor="let option of items" [value]="option">
        {{option}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>
