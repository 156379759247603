import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import * as PlaylistActions from './playlist-actions';
import {PlaylistDto} from '../../shared/models/playlist.dto';


@Injectable()
export class PlaylistDispatcher {
  constructor(private store: Store) {
  }

  fetchPlaylist(playlistId: string) {
    this.store.dispatch(PlaylistActions.fetchPlaylist({playlistId}));
  }

  addLoadedPlaylists(playlists: PlaylistDto[]) {
    this.store.dispatch(PlaylistActions.addLoadedPlaylists({playlists}));
  }

  start() {
    this.store.dispatch(PlaylistActions.start({}));
  }

  done() {
    this.store.dispatch(PlaylistActions.done({}));
  }

}
