import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {AppSelector} from '../../../ngrx/app-selectors';
import {PlaylistDto} from '../../models/playlist.dto';
import {Subscription} from 'rxjs';
import {MusicService} from '../../../ngrx/services/music.service';
import {FormControl, Validators} from '@angular/forms';

@Component({
  selector: 'app-playlist-dialog',
  templateUrl: './playlist-dialog.component.html',
  styleUrls: ['./playlist-dialog.component.scss']
})
export class PlaylistDialogComponent implements OnInit, OnDestroy {

  public playlists: PlaylistDto[];
  public selectedPlaylist: PlaylistDto;
  public newPlaylistControl: FormControl;
  public isError: boolean;
  private subs: Subscription[] = [];

  constructor(private appSelector: AppSelector, private dialogRef: MatDialogRef<PlaylistDialogComponent>,
  ) {
  }

  ngOnInit() {
    this.initForm();
    this.subs.push(this.appSelector.getUserPlaylistsMap().subscribe(playlists => this.playlists = Array.from(playlists.values())));
  }

  initForm() {
    this.newPlaylistControl = new FormControl();
  }

  close() {
    this.dialogRef.close();
  }

  onSubmit() {
    const input: string = this.newPlaylistControl.value;
    if (!this.selectedPlaylist && input && input.trim() !== '') {
      const id = `kp${new Date().getTime().toString()}up`;
      this.selectedPlaylist = new PlaylistDto(id, input, []);
    }

    if (this.selectedPlaylist) {
      this.dialogRef.close(this.selectedPlaylist);
    } else {
      this.isError = true;
    }
  }

  onPlaylistSelected(playlist: PlaylistDto) {
    this.selectedPlaylist = playlist;
  }

  ngOnDestroy(): void {
    if (this.subs) {
      this.subs.forEach(sub => sub.unsubscribe());
    }
  }


}
