import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {Actions, Effect, ofType} from '@ngrx/effects';
import * as SearchActions from './search-actions';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {SearchService} from '../services/search.service';
import {SearchDispatcher} from './search-dispatchers';


@Injectable()
export class SearchEffects {

  constructor(private store: Store, private actions$: Actions,
              private searchDispatcher: SearchDispatcher, private searchApiService: SearchService) {
  }

  @Effect()
  fetchSearchResults$ = this.actions$.pipe(ofType(SearchActions.fetchSearchResults))
    .pipe(mergeMap(requestData => {
      this.searchDispatcher.start();
      return this.searchApiService.fetchSearchResults(requestData.searchTerm)
        .pipe(map(res => {
            this.searchDispatcher.updateSearchResults(res);
            return SearchActions.done({});
          }),
          catchError(error => {
            return of(SearchActions.done({}));
          })
        );
    }));
}
