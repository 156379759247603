import {Component, OnDestroy, OnInit} from '@angular/core';
import {AppSelector} from '../../ngrx/app-selectors';
import {AppDispatcher} from '../../ngrx/app-dispatchers';
import {Subscription} from 'rxjs';
import {SongDto} from '../../shared/models/song.dto';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {PlaylistDialogModule} from '../../shared/dialogs/playlist-dialog/playlist-dialog.module';
import {PlaylistDialogComponent} from '../../shared/dialogs/playlist-dialog/playlist-dialog.component';
import {PlaylistDto} from '../../shared/models/playlist.dto';

@Component({
  selector: 'app-playlist-overlay',
  templateUrl: './playlist-overlay.component.html',
  styleUrls: ['./playlist-overlay.component.scss']
})
export class PlaylistOverlayComponent implements OnInit, OnDestroy {

  public pendingSongs: Map<string, SongDto>;
  private subs: Subscription[] = [];

  constructor(private appSelector: AppSelector, private appDispatcher: AppDispatcher, private dialog: MatDialog) {
  }

  ngOnInit() {
    this.getPendingSongs();
  }

  getPendingSongs() {
    this.subs.push(this.appSelector.getPendingSongs().subscribe(songs => this.pendingSongs = songs));
  }

  onBtnClicked() {
    this.openPlaylistDialog();
  }

  openPlaylistDialog() {
    const dialogConfig = new MatDialogConfig();
    const dialogRef = this.dialog.open(PlaylistDialogComponent);
    this.subs.push(dialogRef.afterClosed().subscribe(playlist => this.crateUpdatePlaylist(playlist)));
  }

  crateUpdatePlaylist(playlist: PlaylistDto) {
    if (playlist) {
      this.appDispatcher.updatePlaylist(playlist, Array.from(this.pendingSongs.values()), true);
      this.appDispatcher.updatePendingSongs(new Map<string, SongDto>());
    }
  }

  ngOnDestroy(): void {
    if (this.subs) {
      this.subs.forEach(sub => sub.unsubscribe());
    }
  }

}
