import {Component, OnDestroy, OnInit} from '@angular/core';
import {SearchService} from '../services/search.service';
import {Subscription} from 'rxjs';
import {SearchSelector} from '../store/search-selectors';
import {SearchDispatcher} from '../store/search-dispatchers';
import {SearchResult} from '../../shared/models/SearchResult';
import {Router} from '@angular/router';
import {AppDispatcher} from '../../ngrx/app-dispatchers';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, OnDestroy {

  public readonly SEARCH_PLACEHOLDER = 'Search for Songs, Albums or Playlists';
  public searchSuggestions: string[] = [];
  public searchResults: SearchResult[];
  public searchedTerm: string;
  public busy: boolean;
  private subs: Subscription[] = [];

  constructor(private searchSelector: SearchSelector, private searchDispatcher: SearchDispatcher,
              private appDispatcher: AppDispatcher, private searchService: SearchService, private router: Router) {
  }

  ngOnInit() {
    this.subs.push(this.searchSelector.getSearchResults().subscribe(results => this.searchResults = results));
    this.subs.push(this.searchSelector.isBusy().subscribe(busy => this.busy = busy));
  }

  onSearchChanged(searchTerm: string) {
    if (!searchTerm || searchTerm === '') {
      this.searchSuggestions = [];
    } else {
      this.searchService.fetchSuggestions(searchTerm).subscribe(suggestions => this.searchSuggestions = suggestions);
    }
  }

  onDoSearch(searchTerm: string) {
    if (searchTerm && searchTerm !== '') {
      this.searchDispatcher.fetchSearchResults(searchTerm);
    }
  }

  onMediaClicked(mediaId, mediaType) {
    if (mediaType === 'Songs') {
      this.appDispatcher.fetchSingleSongToPlay(mediaId);
    } else {
      this.router.navigate([`/playlists/${mediaId}`]);
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }

}
