import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {PlaylistSelector} from '../store/playlist-selectors';
import {PlaylistDispatcher} from '../store/playlist-dispatchers';
import {PlaylistDto} from '../../shared/models/playlist.dto';
import {ActivatedRoute} from '@angular/router';
import {SongDto} from '../../shared/models/song.dto';
import {AppDispatcher} from '../../ngrx/app-dispatchers';
import {AppSelector} from '../../ngrx/app-selectors';
import {UtilsService} from '../../ngrx/services/utils.service';

@Component({
  selector: 'app-playlist',
  templateUrl: './playlist.component.html',
  styleUrls: ['./playlist.component.scss']
})
export class PlaylistComponent implements OnInit, OnDestroy {

  public busy: boolean;
  public userPlaylists: Map<string, PlaylistDto>;
  public loadedPlaylistsMap: Map<string, PlaylistDto>;
  public loadedPlaylist: PlaylistDto;
  public playlistId: string;
  public nowPlayingSongId: string;
  public isLiked: boolean;
  public isUserPlaylist: boolean;
  private subs: Subscription[] = [];

  constructor(private route: ActivatedRoute, private plSelector: PlaylistSelector,
              private plDispatcher: PlaylistDispatcher, private appSelector: AppSelector,
              private appDispatcher: AppDispatcher) {
  }

  ngOnInit() {
    this.route.paramMap.subscribe((data: any) => this.playlistId = data.params.id).unsubscribe();
    this.isUserPlaylist = UtilsService.isUserPlaylist(this.playlistId);
    this.getBusyStatus();
    this.getLastUserPlaylistUpdated();
    this.getUserPlaylists();
    this.getLoadedPlaylists();
    this.getNowPlaying();
    this.getIsLiked();
  }

  getBusyStatus() {
    this.subs.push(this.plSelector.isBusy().subscribe(busy => this.busy = busy));
  }

  getLastUserPlaylistUpdated() {
    this.subs.push(this.appSelector.getLastUpdatedUserPlaylist().subscribe(playlist => this.onUserPlaylistUpdated(playlist)));
  }

  getUserPlaylists() {
    this.subs.push(this.appSelector.getUserPlaylistsMap().subscribe(userPlaylists => this.userPlaylists = userPlaylists));
  }

  getNowPlaying() {
    this.subs.push(this.appSelector.getNowPlaying().subscribe(nowPlaying => {
      if (this.loadedPlaylist && nowPlaying.playlist.id === this.loadedPlaylist.id) {
        this.nowPlayingSongId = nowPlaying.song.id;
      }
    }));
  }

  getLoadedPlaylists() {
    this.subs.push(this.plSelector.getLoadedPlaylists().subscribe(playlistsMap => {
      this.loadedPlaylistsMap = playlistsMap;
      this.loadPlaylist();
    }));
  }

  getIsLiked() {
    this.subs.push(this.appSelector.getUserLibrary().subscribe(lib => this.isLiked = lib.has(this.playlistId)));
  }

  loadPlaylist() {
    this.loadedPlaylist = this.loadedPlaylistsMap.get(this.playlistId) || this.userPlaylists.get(this.playlistId);
    if (!this.loadedPlaylist) {
      this.plDispatcher.fetchPlaylist(this.playlistId);
    }
  }

  onUserPlaylistUpdated(playlist: PlaylistDto) {
    if (playlist && this.loadedPlaylist && this.loadedPlaylist.id === playlist.id) {
      this.loadedPlaylist = playlist;
    }
  }

  onSongClicked(song: SongDto) {
    this.appDispatcher.setNowPlaying(song, this.loadedPlaylist);
  }

  playPlaylist() {
    this.onSongClicked(this.loadedPlaylist.tracks[0]);
  }

  onLike() {
    this.appDispatcher.updateUserLibrary(this.loadedPlaylist, this.isLiked);
  }

  addPlaylistSongs() {
    const map = new Map<string, SongDto>();
    this.loadedPlaylist.tracks.forEach(s => map.set(s.id, s));
    this.appDispatcher.updatePendingSongs(map);
  }

  onRemove(songToRemove: SongDto) {
    this.appDispatcher.updatePlaylist(this.loadedPlaylist, [songToRemove], false);
  }

  ngOnDestroy(): void {
    this.plDispatcher.done();
    this.subs.forEach(sub => sub.unsubscribe());
  }

}
