<div class="pb-video__container" [ngClass]="{'pb-video__wide': wideView}">
  <div class="pb-video__video">
    <ng-content></ng-content>
    <img [src]="img ? img : 'assets/images/tmp.png'" class="pb-video__cover"
         [ngClass]="{'pb-video__hidden': playing, 'pb-video__full': wideView}">
  </div>
  <div class="pb-video__song-info">
    <div class="pb-video__info-title pb-video__animation">{{song?.name}}</div>
    <div class="pb-video__info-artist">{{song?.artist}}</div>
  </div>
</div>
