import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-buttons-bar',
  templateUrl: './buttons-bar.component.html',
  styleUrls: ['./buttons-bar.component.scss']
})
export class ButtonsBarComponent implements OnInit {

  public readonly PLAY = 'play_circle_outline';
  public readonly PAUSE = 'pause_circle_outline';
  public readonly DATA_USAGE = 'data_usage';
  public readonly ARROW_LEFT = 'arrow_left';
  public readonly ARROW_RIGHT = 'arrow_right';
  public readonly SHUFFLE = 'shuffle';
  public readonly REPEAT = 'repeat';

  @Input() playing: boolean;
  @Input() shuffle: boolean;
  @Input() repeat: boolean;
  @Input() buffering: boolean;
  @Input() collapsed: boolean;
  @Input() wide: boolean;
  @Input() public forceBuffering: boolean;
  @Output() onPlayPause: EventEmitter<void> = new EventEmitter<void>();
  @Output() onNextPrev: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onShuffle: EventEmitter<void> = new EventEmitter<void>();
  @Output() onRepeat: EventEmitter<void> = new EventEmitter<void>();

  constructor() {
  }

  ngOnInit() {
  }

  playPauseSong(event) {
    event.stopPropagation();
    this.onPlayPause.emit();
  }

  nextPreviousVideo(event, isNext: boolean) {
    event.stopPropagation();
    this.onNextPrev.emit(isNext);
  }

  shuffleClicked(event) {
    event.stopPropagation();
    this.onShuffle.emit();
  }

  repeatClicked(event) {
    event.stopPropagation();
    this.onRepeat.emit();
  }
}
