import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pb-collapsed',
  templateUrl: './pb-collapsed.component.html',
  styleUrls: ['./pb-collapsed.component.scss']
})
export class PbCollapsedComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
