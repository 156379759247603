import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import * as AppActions from './app-actions';
import {SongDto} from '../shared/models/song.dto';
import {LIBRARY_TYPE} from '../shared/enums/LIBRARY_TYPE';
import {PlaylistDto} from '../shared/models/playlist.dto';


@Injectable()
export class AppDispatcher {
  constructor(private store: Store) {
  }

  fetchSongsLibrary(libraryType: LIBRARY_TYPE) {
    this.store.dispatch(AppActions.fetchSongsLibrary({libraryType}));
  }

  setSongsLibrary(libraryType: LIBRARY_TYPE, playlist: PlaylistDto) {
    this.store.dispatch(AppActions.setSongsLibrary({libraryType, playlist}));
  }

  fetchUserPlaylists() {
    this.store.dispatch(AppActions.fetchAllUserPlaylists({}));
  }

  setUserPlaylists(playlists: Map<string, PlaylistDto>) {
    this.store.dispatch(AppActions.setUserPlaylists({playlists}));
  }

  fetchUserLibrary() {
    this.store.dispatch(AppActions.fetchUserLibrary({}));
  }

  updateUserLibrary(playlist: PlaylistDto, toDelete?: boolean) {
    this.store.dispatch(AppActions.updateUserLibrary({playlist, toDelete}));
  }

  setUserLibrary(library: Map<string, PlaylistDto>) {
    this.store.dispatch(AppActions.setUserLibrary({library}));
  }

  setNowPlaying(song: SongDto, playlist: PlaylistDto, isInitiatedByPlayer = false) {
    this.store.dispatch(AppActions.setNowPlaying({song, playlist, isInitiatedByPlayer}));
  }

  updatePlaylist(playlist: PlaylistDto, songs: SongDto[], toAdd: boolean) {
    this.store.dispatch(AppActions.updatePlaylist({playlist, songs, toAdd}));
  }

  playlistUpdated(playlist: PlaylistDto) {
    this.store.dispatch(AppActions.playlistUpdated({playlist}));
  }

  updatePendingSongs(songs: Map<string, SongDto>) {
    this.store.dispatch(AppActions.updatePendingSongs({songs}));
  }

  updatePendingSong(song: SongDto, toDelete: boolean) {
    this.store.dispatch(AppActions.updatePendingSong({song, toDelete}));
  }

  fetchSingleSongToPlay(songId: string) {
    this.store.dispatch(AppActions.fetchSingleSongToPlay({songId}));
  }

  setSingleSongBusy(busy: boolean) {
    this.store.dispatch(AppActions.setSingleSongBusy({busy}));
  }

  updateBackgroundColor(backgroundColor: string) {
    this.store.dispatch(AppActions.updateBackgroundColor({backgroundColor}));
  }

  updateRecentSeeds(seed: string) {
    this.store.dispatch(AppActions.updateRecentSeeds({seed}));
  }

  requestStarted() {
    this.store.dispatch(AppActions.requestStarted({}));
  }

  requestEnded() {
    this.store.dispatch(AppActions.requestEnded({}));
  }

}
