import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SideMenuComponent } from './side-menu.component';
import {RouterModule} from '@angular/router';

@NgModule({
    declarations: [SideMenuComponent],
    exports: [
        SideMenuComponent
    ],
  imports: [
    CommonModule,
    RouterModule
  ]
})
export class SideMenuModule { }
