<div class="import__container">
  <app-kp-content class="import__content">
    <div class="import__title">Search for Songs, Albums or Playlists</div>
    <div class="import__sub">
      <div class="import__or">or</div>
      <button class="mat-raised-button kp-primary-btn" (click)="openImportDialog()">
        Import a playlist from Youtube/Spotify
      </button>
    </div>
  </app-kp-content>
</div>
