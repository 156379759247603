<div class="progress-bar__container">
  <div class="progress-bar__time">{{value | duration}}</div>
  <div class="progress-bar__bar-section">
    <div class="progress-bar__bar" (click)="onProgressBarClicked($event)"></div>
    <div *ngIf="value !== 0" class="progress-bar__progress"
         [ngClass]="{'progress-bar__smooth': smooth}"
         [ngStyle]="{'right.%': value | progress: total : true}"></div>
  </div>
  <div class="progress-bar__time">{{total | duration}}</div>
</div>
