import {Component, OnInit} from '@angular/core';
import {MenuService, SideMenuItem} from '../menu.service';


@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {

  public menuItems: SideMenuItem[];

  constructor() {
  }

  ngOnInit() {
    this.menuItems = MenuService.menuItems;
  }

}
