import {createAction, props} from '@ngrx/store';
import {FeaturedContentPayload, MediaItemsPayload, RecentlyPlayedPayload} from './entities/payloads';

export const fetchCategories = createAction('[KP_Homepage] Fetch Categories', props<{}>());
export const setCategories = createAction('[KP_Homepage] Set Categories', props<FeaturedContentPayload>());
export const fetchRecommendations = createAction('[KP_Homepage] Fetch Recommendations', props<{}>());
export const setRecommendations = createAction('[KP_Homepage] Set Recommendations', props<MediaItemsPayload>());
export const fetchRecentlyPlayed = createAction('[KP_Homepage] Fetch Recently Played', props<{}>());
export const setRecentlyPlayed = createAction('[KP_Homepage] Set Recently Played', props<RecentlyPlayedPayload>());
export const done = createAction('[KP_Homepage] Done', props<{}>());

