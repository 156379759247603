import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SearchComponent} from './search-main/search.component';
import {AutocompleteModule} from '../shared/components/autocomplete/autocomplete.module';
import {PlaylistSelector} from '../playlist/store/playlist-selectors';
import {PlaylistDispatcher} from '../playlist/store/playlist-dispatchers';
import {SearchSelector} from './store/search-selectors';
import {SearchDispatcher} from './store/search-dispatchers';
import {SpinnerModule} from '../shared/spinner/spinner.module';
import {MediaItemModule} from '../shared/components/media-item/media-item.module';
import {RouterModule} from '@angular/router';
import {KpContentModule} from '../shared/wrappers/kp-content/kp-content.module';
import {ImportComponent} from './import/import.component';
import {ImportDialogComponent} from './import-dialog/import-dialog.component';
import {KpDialogModule} from '../shared/wrappers/kp-dialog/kp-dialog.module';
import {MatDialogModule} from '@angular/material/dialog';
import {MatInputModule} from '@angular/material/input';

@NgModule({
  declarations: [SearchComponent, ImportComponent, ImportDialogComponent],
  imports: [
    CommonModule,
    AutocompleteModule,
    SpinnerModule,
    MediaItemModule,
    RouterModule,
    KpContentModule,
    KpDialogModule,
    MatDialogModule,
    MatInputModule,
  ],
  entryComponents: [ImportDialogComponent],
  exports: [SearchComponent, ImportDialogComponent],
  providers: [
    SearchSelector,
    SearchDispatcher
  ]
})
export class SearchModule {
}
