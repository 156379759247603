import {Action, createFeatureSelector, createReducer, createSelector, on} from '@ngrx/store';
import {PlaylistDto} from '../../shared/models/playlist.dto';
import * as PlaylistActions from './playlist-actions';

export interface PlaylistState {
  isBusy: boolean;
  loadedPlaylists: Map<string, PlaylistDto>;
}

export const initialState: PlaylistState = {
  isBusy: false,
  loadedPlaylists: new Map<string, PlaylistDto>(),
};

const PlaylistReducer = createReducer(
  initialState,

  on(PlaylistActions.start, (state, {}) => ({...state, isBusy: true})),

  on(PlaylistActions.done, (state, {}) => ({...state, isBusy: false})),

  on(PlaylistActions.addLoadedPlaylists, (state, {playlists}) => {
    playlists.forEach(p => state.loadedPlaylists.set(p.id, p));
    return {...state, loadedPlaylists: new Map(state.loadedPlaylists)};
  }),

);

export function PlaylistReducers(state: PlaylistState | undefined, action: Action) {
  return PlaylistReducer(state, action);
}

export const selectAppState = createFeatureSelector<PlaylistState>('KP_Playlist');

export const IsBusy = (state: PlaylistState) => state.isBusy;

export const GetLoadedPlaylists = (state: PlaylistState) => state.loadedPlaylists;

export const isBusy = () => createSelector(
  selectAppState,
  IsBusy
);

export const getLoadedPlaylists = () => createSelector(
  selectAppState,
  GetLoadedPlaylists
);

