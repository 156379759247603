import {Component, OnInit} from '@angular/core';
import {EXTERNAL_SOURCE} from '../../shared/enums/EXTERNAL_SOURCE';
import {MatDialogRef} from '@angular/material/dialog';

class CheckResult {
  constructor(public result: string, public type: EXTERNAL_SOURCE, public subType?: EXTERNAL_SOURCE) {
  }
}

@Component({
  selector: 'app-import-dialog',
  templateUrl: './import-dialog.component.html',
  styleUrls: ['./import-dialog.component.scss']
})
export class ImportDialogComponent implements OnInit {

  private readonly ALBUM: string = 'album/';
  private readonly PLAYLIST: string = 'playlist/';
  public isError: boolean;

  constructor(private dialogRef: MatDialogRef<ImportDialogComponent>) {
  }

  ngOnInit() {
  }

  onFetchClicked(input: string) {
    const result = this.parseYoutube(input) || this.parseSpotify(input);
    if (result) {
      const mediaId = `kp${result.result.split('').reverse().join('')}${result.subType}`;
      this.isError = false;
      this.dialogRef.close(mediaId);
    } else {
      this.isError = true;
    }
  }

  parseYoutube(input: string): CheckResult {
    return this.parseYouTubePlaylist(input) || this.parseYoutubeVideo(input);
  }

  parseYoutubeVideo(input: string): CheckResult {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = input.match(regExp);
    const result: string = (match && match[7].length === 11) ? match[7] : null;
    return result ? new CheckResult(result, EXTERNAL_SOURCE.YOUTUBE, EXTERNAL_SOURCE.YOUTUBE_VIDEO) : null;
  }

  parseYouTubePlaylist(input: string) {
    const regExpPlaylist = /^.*(youtu.be\/|list=)([^#\&\?]*).*/;
    const matchPlaylist = input.match(regExpPlaylist);
    const result: string = (matchPlaylist && matchPlaylist[2]) ? matchPlaylist[2] : null;
    return result ? new CheckResult(result, EXTERNAL_SOURCE.YOUTUBE, EXTERNAL_SOURCE.YOUTUBE_PLAYLIST) : null;
  }

  parseSpotify(input: string): CheckResult {
    let checkResult: CheckResult = null;
    const albumIndex: number = input.indexOf(this.ALBUM);
    const playlistIndex: number = input.indexOf(this.PLAYLIST);
    if (input.indexOf('spotify') !== -1 && (playlistIndex !== -1 || albumIndex !== -1)) {
      let result: string;
      const additionalParamIndex: number = input.indexOf('?');
      const contentIndex: number = playlistIndex !== -1 ? playlistIndex : albumIndex;
      const type: string = playlistIndex !== -1 ? this.PLAYLIST : this.ALBUM;
      const subType: EXTERNAL_SOURCE = playlistIndex !== -1 ? EXTERNAL_SOURCE.SPOTIFY_PLAYLISTS : EXTERNAL_SOURCE.SPOTIFY_ALBUMS;
      if (additionalParamIndex === -1) {
        result = input.substr(contentIndex + type.length);
      } else {
        result = input.substring(contentIndex + type.length, additionalParamIndex);
      }
      checkResult = new CheckResult(result, EXTERNAL_SOURCE.SPOTIFY, subType);
    }
    return checkResult;
  }

  close() {
    this.dialogRef.close();
  }

}
