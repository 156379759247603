import {Store} from '@ngrx/store';
import {Injectable} from '@angular/core';
import {getSearchResults, isBusy} from './search-reducers';

@Injectable()
export class SearchSelector {
  constructor(private store: Store) {
  }

  isBusy() {
    return this.store.select(isBusy());
  }

  getSearchResults() {
    return this.store.select(getSearchResults());
  }
}
