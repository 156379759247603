import {createAction, props} from '@ngrx/store';
import {
  BackgroundColorPayload,
  LibraryDataPayload,
  LibraryTypePayload,
  NowPlayingPayload,
  PendingSongPayload,
  PendingSongsPayload,
  PlaylistPayload,
  PlaylistsMapPayload,
  RecentSeedPayload,
  SingleSongBusyPayload,
  SingleSongToPlayPayload,
  UpdatePlaylistPayload,
  UpdateUserLibraryPayload,
  UserLibraryPayload
} from './entities/payloads';

export const requestStarted = createAction('[KeePlaying] Request Started', props<{}>());
export const requestEnded = createAction('[KeePlaying] Request Ended', props<{}>());

export const fetchSongsLibrary = createAction('[KeePlaying] Fetch Songs Library', props<LibraryTypePayload>());
export const setSongsLibrary = createAction('[KeePlaying] Set Songs Library', props<LibraryDataPayload>());

export const fetchAllUserPlaylists = createAction('[KeePlaying] Fetch All User Playlists', props<{}>());
export const setUserPlaylists = createAction('[KeePlaying] Set User Playlists', props<PlaylistsMapPayload>());
export const updatePlaylist = createAction('[KeePlaying] Update Playlist', props<UpdatePlaylistPayload>());
export const playlistUpdated = createAction('[KeePlaying] PlaylistDto Updated', props<PlaylistPayload>());

export const setNowPlaying = createAction('[KeePlaying] Set Now Playing', props<NowPlayingPayload>());
export const updatePendingSongs = createAction('[KeePlaying] Update Pending Songs', props<PendingSongsPayload>());
export const updatePendingSong = createAction('[KeePlaying] Update Pending SongDto', props<PendingSongPayload>());

export const fetchSingleSongToPlay = createAction('[KeePlaying] Fetch Single SongDto To Play', props<SingleSongToPlayPayload>());
export const setSingleSongBusy = createAction('[KeePlaying] Set Single SongDto To Play', props<SingleSongBusyPayload>());

export const fetchUserLibrary = createAction('[KeePlaying] Fetch User Library', props<{}>());
export const updateUserLibrary = createAction('[KeePlaying] Update User Library', props<UpdateUserLibraryPayload>());
export const setUserLibrary = createAction('[KeePlaying] Set User Library', props<UserLibraryPayload>());

export const updateBackgroundColor = createAction('[KP_Playlist] Update Background Color', props<BackgroundColorPayload>());

export const updateRecentSeeds = createAction('[KeePlaying] Update Recent Seeds', props<RecentSeedPayload>());

export const done = createAction('[KeePlaying] Done', props<{}>());




