<div class="kp__container">
  <div class="keep-playing__bla">
    <div class="kp__menu">
      <app-side-menu></app-side-menu>
    </div>
    <div class="kp__body">
      <router-outlet></router-outlet>
    </div>
    <app-background></app-background>
  </div>

  <div class="keep-playing__footer">
    <div class="keep-playing__now-playing" [ngClass]="{'kp__hidden': !showPlayingBar}">
      <app-playing-bar></app-playing-bar>
    </div>
    <div class="keep-playing__nav-menu">
      <app-nav-menu></app-nav-menu>
    </div>
  </div>
</div>

<div *ngIf="pendingSongsMap.size" class="kp__overlay" [ngClass]="{'kp__overlay-reposition': showPlayingBar}">
  <app-playlist-overlay></app-playlist-overlay>
</div>
