import {Action, createFeatureSelector, createReducer, createSelector, on} from '@ngrx/store';
import * as SearchActions from './search-actions';
import {SearchResult} from '../../shared/models/SearchResult';

export interface State {
  isBusy: boolean;
  searchResults: SearchResult[];
}

export const initialState: State = {
  isBusy: false,
  searchResults: [],

};

const SearchReducer = createReducer(
  initialState,

  on(SearchActions.start, (state, {}) => ({...state, isBusy: true})),

  on(SearchActions.done, (state, {}) => ({...state, isBusy: false})),

  on(SearchActions.updateSearchResults, (state, {searchResults}) => ({...state, searchResults})),
);

export function SearchReducers(state: State | undefined, action: Action) {
  return SearchReducer(state, action);
}

export const selectAppState = createFeatureSelector<State>('KP_Search');

export const IsBusy = (state: State) => state.isBusy;

export const GetSearchResults = (state: State) => state.searchResults;

export const isBusy = () => createSelector(
  selectAppState,
  IsBusy
);

export const getSearchResults = () => createSelector(
  selectAppState,
  GetSearchResults
);
