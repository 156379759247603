import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {LIBRARY_TYPE} from '../../shared/enums/LIBRARY_TYPE';
import {ActivatedRoute} from '@angular/router';
import {HomepageDispatcher} from '../store/homepage-dispatchers';
import {HomepageSelector} from '../store/homepage-selectors';
import {AppSelector} from '../../ngrx/app-selectors';
import {FeaturedContentDto} from '../../shared/models/featured-content.dto';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit, OnDestroy {

  public readonly CARDS = [
    {
      type: LIBRARY_TYPE.YEARS70,
      text: '70s Billboard',
      artist: 'Galgalatz',
      img: 'assets/images/70s.png',
      url: 'kpfkRg3edUXbZpfPes04rfH1sp'
    },
    {
      type: LIBRARY_TYPE.YEARS80,
      text: '80s Billboard',
      artist: 'Galgalatz',
      img: 'assets/images/80s.png',
      url: 'kpSVpoAooYW5jz7xn6RUFwW2sp'
    },
    {
      type: LIBRARY_TYPE.YEARS90,
      text: '90s Billboard',
      artist: 'Galgalatz',
      img: 'assets/images/90s.png',
      url: 'kpSVpoAooYW5jz7xn6RUFwW2sp'
    },
    {
      type: LIBRARY_TYPE.YEARS00,
      text: '00s Billboard',
      artist: 'Galgalatz',
      img: 'assets/images/00s.png',
      url: 'kpSVpoAooYW5jz7xn6RUFwW2sp'
    },
    {
      type: LIBRARY_TYPE.YEARS10,
      text: '10s Billboard',
      artist: 'Galgalatz',
      img: 'assets/images/10s.png',
      url: 'kpSVpoAooYW5jz7xn6RUFwW2sp'
    },
  ];

  public categories: FeaturedContentDto[] = [];
  public featuredContentsLoaded: boolean;
  public isLibrary: boolean;
  private subs: Subscription[] = [];

  constructor(private hpSelector: HomepageSelector, private hpDispatcher: HomepageDispatcher,
              private appSelector: AppSelector, private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.route.data.subscribe(data => {
      if (data.library) {
        this.isLibrary = true;
        this.getUserPlaylists();
        this.getUserLibrary();
        this.featuredContentsLoaded = true;
      } else {
        this.hpDispatcher.fetchRecentlyPlayed();
        this.getRecentlyPlayed();
        this.getRecommendations();
        this.getFeaturedContent();
        if (!this.featuredContentsLoaded) {
          this.hpDispatcher.fetchRecommendations();
          this.hpDispatcher.fetchCategories();
        }
      }
    });
  }

  getUserPlaylists() {
    this.subs.push(this.appSelector.getUserPlaylistsMap()
      .subscribe(userPlaylists => this.categories.push({name: 'My Playlists', items: Array.from(userPlaylists.values())})));
  }

  getUserLibrary() {
    this.subs.push(this.appSelector.getUserLibrary()
      .subscribe(userLibrary => this.categories.push({name: 'Liked', items: Array.from(userLibrary.values())})));
  }

  getRecentlyPlayed() {
    this.subs.push(this.hpSelector.getRecentlyPlayed()
      .subscribe(playlists => this.categories.push({name: 'Recently Played', items: playlists})));
  }

  getFeaturedContent() {
    this.subs.push(this.hpSelector.getCategories()
      .subscribe(homepageContent => {
        this.categories = [...this.categories, ...homepageContent];
        this.featuredContentsLoaded = homepageContent.length > 0;
      }));
  }

  getRecommendations() {
    this.subs.push(this.hpSelector.getRecommendations()
      .subscribe(getRecommendations => this.categories.splice(1, 0, {name: 'Made For You', items: getRecommendations})));
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}
