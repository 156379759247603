<div class="search__container">
  <div class="search__bar">
    <div class="search__input">
      <app-autocomplete [placeholder]="SEARCH_PLACEHOLDER" [items]="searchSuggestions"
                        (onChange)="onSearchChanged($event)" (onSubmit)="onDoSearch($event)"></app-autocomplete>
    </div>
  </div>
  <div>
    <app-import></app-import>
  </div>
  <app-spinner *ngIf="busy"></app-spinner>
  <app-kp-content *ngIf="!busy">
    <div *ngFor="let searchResult of searchResults">
      <div class="search__section">
        <div class="search__headline">{{searchResult.type}}</div>
        <div class="search__media-items">
          <div class="search__media-item" *ngFor="let playlist of searchResult.media">
            <app-media-item [title]="playlist.name" [subTitle]="playlist.artist" [img]="playlist.img"
                            (click)="onMediaClicked(playlist.id, searchResult.type)"></app-media-item>
          </div>
        </div>
      </div>
    </div>
  </app-kp-content>
</div>
