import {Injectable} from '@angular/core';
import {EXTERNAL_SOURCE} from '../../shared/enums/EXTERNAL_SOURCE';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() {
  }

  static isUserPlaylist(playlistId: string) {
    let result = false;
    if (playlistId) {
      result = playlistId.slice(-2) === EXTERNAL_SOURCE.USER_PLAYLIST;
    }
    return result;
  }


}
