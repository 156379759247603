import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import * as HomepageActions from './homepage-actions';
import {PlaylistDto} from '../../shared/models/playlist.dto';
import {FeaturedContentDto} from '../../shared/models/featured-content.dto';
import {MediaItemDto} from '../../shared/models/media-item.dto';


@Injectable()
export class HomepageDispatcher {
  constructor(private store: Store) {
  }

  fetchCategories() {
    this.store.dispatch(HomepageActions.fetchCategories({}));
  }

  setCategories(featuredContents: FeaturedContentDto[]) {
    this.store.dispatch(HomepageActions.setCategories({featuredContents}));
  }

  fetchRecommendations() {
    this.store.dispatch(HomepageActions.fetchRecommendations({}));
  }

  setRecommendations(mediaItems: MediaItemDto[]) {
    this.store.dispatch(HomepageActions.setRecommendations({mediaItems}));
  }

  fetchRecentlyPlayed() {
    this.store.dispatch(HomepageActions.fetchRecentlyPlayed({}));
  }

  setRecentlyPlayed(playlists: PlaylistDto[]) {
    this.store.dispatch(HomepageActions.setRecentlyPlayed({playlists}));
  }
}
