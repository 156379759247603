import {Component, Input, OnInit} from '@angular/core';
import {ActionMenuItem} from '../models/ActionMenuItem';

@Component({
  selector: 'app-action-menu',
  templateUrl: './action-menu.component.html',
  styleUrls: ['./action-menu.component.scss']
})
export class ActionMenuComponent implements OnInit {

  @Input() items: ActionMenuItem[] = [];
  @Input() isOpen: boolean;

  constructor() {
  }

  ngOnInit() {
  }

  onOpenMenuClicked(event) {
    this.isOpen = !this.isOpen;
    event.stopPropagation();
  }

  onClickOutside() {
    this.isOpen = false;
  }

  onClick(item: ActionMenuItem, $event: MouseEvent) {
    if (item.action) {
      item.action($event);
    }
    this.isOpen = false;
    event.stopPropagation();
  }
}
