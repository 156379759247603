import {Component, OnInit} from '@angular/core';
import {MenuService, SideMenuItem} from '../menu.service';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss']
})
export class NavMenuComponent implements OnInit {

  public menuItems: SideMenuItem[];


  constructor() {
  }

  ngOnInit() {
    this.menuItems = MenuService.menuItems;
  }

}
