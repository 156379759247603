import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomepageComponent} from './homepage/homepage-main/homepage.component';
import {PlaylistComponent} from './playlist/playlist-main/playlist.component';
import {SearchComponent} from './search/search-main/search.component';

const routes: Routes = [
  {path: '', component: HomepageComponent},
  {path: 'search', component: SearchComponent},
  {path: 'library', component: HomepageComponent, data: {library: true}},
  {path: 'playlists/:id', component: PlaylistComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
