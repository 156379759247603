import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProgressPipe} from './progress.pipe';

@NgModule({
  declarations: [ProgressPipe],
  exports: [
    ProgressPipe,
  ],
  imports: [
    CommonModule
  ]
})
export class ProgressPipeModule {
}
