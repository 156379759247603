import {MediaItemDto} from './media-item.dto';

export class SongDto extends MediaItemDto {
  public altId: string;

  constructor(id?, name?, artist?, img?,
              public lengthSeconds = 0,
              public backups: string[] = [],
              public category?: string,
              public seed?: string) {
    super(id, name, artist, img);
  }
}
