<div *ngIf="!collapsed" class="buttons-bar__container" [ngClass]="{'buttons-bar__wide': wide}">
  <i class="material-icons buttons-bar__icon-s" title="{{shuffle ? 'Disable' : 'Enable'}} shuffle"
     (click)="shuffleClicked($event)" [ngClass]="{'buttons-bar__enabled': shuffle}">{{SHUFFLE}}</i>
  <i class="material-icons buttons-bar__icon-m" title="Previous"
     (click)="nextPreviousVideo($event,false)">{{ARROW_LEFT}}</i>
  <i class="material-icons buttons-bar__icon-l" title="{{playing ? 'Pause' : 'Play'}}"
     (click)="playPauseSong($event)">{{forceBuffering || buffering ? DATA_USAGE : playing ? PAUSE : PLAY}}</i>
  <i class="material-icons buttons-bar__icon-m" title="Next"
     (click)="nextPreviousVideo($event,true)">{{ARROW_RIGHT}}</i>
  <i class="material-icons buttons-bar__icon-s"
     title="{{repeat ? 'Disable' : 'Enable'}} repeat"
     (click)="repeatClicked($event)" [ngClass]="{'buttons-bar__enabled': repeat}">{{REPEAT}}</i>
</div>

<div *ngIf="collapsed" class="buttons-bar__container">
  <i class="material-icons buttons-bar__icon-l" title="{{playing ? 'Pause' : 'Play'}}"
     (click)="playPauseSong($event)">{{forceBuffering || buffering ? DATA_USAGE : playing ? PAUSE : PLAY}}</i>
</div>
