import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KpHorizontalComponent } from './kp-horizontal.component';

@NgModule({
    declarations: [KpHorizontalComponent],
    exports: [KpHorizontalComponent],
    imports: [
        CommonModule
    ]
})
export class KpHorizontalModule { }
