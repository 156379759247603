import {Store} from '@ngrx/store';
import {Injectable} from '@angular/core';
import {getCategories, getRecentlyPlayed, getRecommendations} from './homepage-reducers';

@Injectable()
export class HomepageSelector {
  constructor(private store: Store) {
  }

  getCategories() {
    return this.store.select(getCategories());
  }

  getRecommendations() {
    return this.store.select(getRecommendations());
  }

  getRecentlyPlayed() {
    return this.store.select(getRecentlyPlayed());
  }
}
