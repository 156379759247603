import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HomepageComponent} from './homepage-main/homepage.component';
import {MediaItemModule} from '../shared/components/media-item/media-item.module';
import {RouterModule} from '@angular/router';
import {KpContentModule} from '../shared/wrappers/kp-content/kp-content.module';
import {KpHorizontalModule} from '../shared/wrappers/kp-horizontal/kp-horizontal.module';
import {HomepageSelector} from './store/homepage-selectors';
import {HomepageDispatcher} from './store/homepage-dispatchers';
import { HomepageStripComponent } from './homepage-strip/homepage-strip.component';
import {SpinnerModule} from '../shared/spinner/spinner.module';

@NgModule({
  declarations: [HomepageComponent, HomepageStripComponent],
  imports: [
    CommonModule,
    MediaItemModule,
    RouterModule,
    KpContentModule,
    KpHorizontalModule,
    SpinnerModule
  ],
  providers: [
    HomepageSelector,
    HomepageDispatcher
  ]
})
export class HomepageModule {
}
