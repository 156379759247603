import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PlaylistDialogComponent} from './playlist-dialog.component';
import {MatRadioModule} from '@angular/material/radio';
import {MatInputModule} from '@angular/material/input';
import {MatDialogModule} from '@angular/material/dialog';
import {ReactiveFormsModule} from '@angular/forms';
import {KpDialogModule} from '../../wrappers/kp-dialog/kp-dialog.module';

@NgModule({
  declarations: [PlaylistDialogComponent],
  exports: [PlaylistDialogComponent],
  entryComponents: [PlaylistDialogComponent],
    imports: [
        CommonModule,
        MatRadioModule,
        MatInputModule,
        MatDialogModule,
        ReactiveFormsModule,
        KpDialogModule
    ],
})
export class PlaylistDialogModule {
}
