import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {ImportDialogComponent} from '../import-dialog/import-dialog.component';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';

@Component({
  selector: 'app-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.scss']
})
export class ImportComponent implements OnInit, OnDestroy {

  private subs: Subscription[] = [];

  constructor(private dialogService: MatDialog, private route: Router) {
  }

  ngOnInit() {
  }

  openImportDialog() {
    const dialogConfig = new MatDialogConfig();
    const dialogRef = this.dialogService.open(ImportDialogComponent);
    this.subs.push(dialogRef.afterClosed().subscribe(url => this.importContent(url)));
  }

  importContent(url: string) {
    if (url) {
      this.route.navigateByUrl(`playlists/${url}`);
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }

}
