import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {FeaturedContentDto} from '../../../shared/models/featured-content.dto';
import {MusicService} from '../../../ngrx/services/music.service';
import {MediaItemDto} from '../../../shared/models/media-item.dto';

@Injectable({
  providedIn: 'root'
})
export class HomepageService {

  private readonly CATEGORIES_URL: string = `${environment.serverUrl}search/categories`;
  private readonly RECOMMENDATIONS_URL: string = `${environment.serverUrl}search/recommendations`;

  constructor(private http: HttpClient) {
  }

  fetchCategories(): Observable<FeaturedContentDto[]> {
    return this.http.get(this.CATEGORIES_URL).pipe(map(res => res as FeaturedContentDto[]));
  }

  fetchRecommendations(): Observable<MediaItemDto[]> {
    const recentSeeds: string[] = MusicService.loadRecentSeeds();
    return this.http.get(`${this.RECOMMENDATIONS_URL}?seeds=${recentSeeds}`).pipe(map(res => res as MediaItemDto[]));
  }

}
