import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SongDto} from '../../shared/models/song.dto';
import {ActionMenuItem} from '../../shared/models/ActionMenuItem';
import {AppDispatcher} from '../../ngrx/app-dispatchers';

@Component({
  selector: 'app-playlist-item',
  templateUrl: './playlist-item.component.html',
  styleUrls: ['./playlist-item.component.scss']
})
export class PlaylistItemComponent implements OnInit {

  @Input() public song: SongDto;
  @Input() public index: number;
  @Input() public selected: boolean;
  @Input() public editable: boolean;
  @Output() public onClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() public onRemove: EventEmitter<void> = new EventEmitter<void>();
  public isPending: boolean;
  public menuItems: ActionMenuItem[] = [];

  constructor(private appDispatcher: AppDispatcher) {
  }

  ngOnInit() {
    this.initMenuActions();
  }

  initMenuActions() {
    this.menuItems = [
      new ActionMenuItem('Copy song', () => this.togglePendingSong()),
    ];
    if (this.editable) {
      this.menuItems.push(new ActionMenuItem('Remove song from playlist', () => this.onRemove.emit()));
    }
  }

  togglePendingSong() {
    this.appDispatcher.updatePendingSong(this.song, this.isPending);
  }

  onClicked() {
    this.onClick.emit();
  }
}
