import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {Actions, Effect, ofType} from '@ngrx/effects';
import * as HomepageActions from './homepage-actions';
// import * as AppActions from '../../ngrx/app-actions';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {HomepageService} from './services/homepage.service';
import {HomepageDispatcher} from './homepage-dispatchers';
import {MusicService} from '../../ngrx/services/music.service';


@Injectable()
export class HomepageEffects {

  constructor(private store: Store, private actions$: Actions,
              private hpDispatcher: HomepageDispatcher, private homepageService: HomepageService) {
  }

  @Effect()
  fetchCategories$ = this.actions$.pipe(ofType(HomepageActions.fetchCategories))
    .pipe(mergeMap(requestData => {
      return this.homepageService.fetchCategories()
        .pipe(map(res => {
            this.hpDispatcher.setCategories(res);
            return HomepageActions.done({});
          }),
          catchError(error => {
            return of(HomepageActions.done({}));
          })
        );
    }));

  @Effect()
  fetchRecommendations$ = this.actions$.pipe(ofType(HomepageActions.fetchRecommendations))
    .pipe(mergeMap(requestData => {
      return this.homepageService.fetchRecommendations()
        .pipe(map(res => {
            this.hpDispatcher.setRecommendations(res);
            return HomepageActions.done({});
          }),
          catchError(error => {
            return of(HomepageActions.done({}));
          })
        );
    }));

  @Effect()
  fetchRecentlyPlayed$ = this.actions$.pipe(ofType(HomepageActions.fetchRecentlyPlayed))
    .pipe(map(requestData => {
      this.hpDispatcher.setRecentlyPlayed(MusicService.loadRecentlyPlayed());
      return HomepageActions.done({});
    }));

}
