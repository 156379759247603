import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'progress'
})
export class ProgressPipe implements PipeTransform {

  transform(value: number, total: number, remaining: boolean): number {
    const percent: number = total !== 0 ? ((value / total) * 100) : 0;
    const finalPercent: number = percent > 100 ? 100 : percent;
    return remaining ? 100 - finalPercent : finalPercent;
  }

}
