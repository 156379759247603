import {Store} from '@ngrx/store';
import {
  getBackgroundColor,
  getLastUpdatedUserPlaylist,
  getLibraries,
  getNowPlaying,
  getPendingSongs,
  getUserLibrary,
  getUserPlaylistsMap,
  isBusy,
  isSingleSongBusy
} from './app-reducers';
import {Injectable} from '@angular/core';

@Injectable()
export class AppSelector {
  constructor(private store: Store) {
  }

  getLibraries() {
    return this.store.select(getLibraries());
  }

  getUserPlaylistsMap() {
    return this.store.select(getUserPlaylistsMap());
  }

  getUserLibrary() {
    return this.store.select(getUserLibrary());
  }

  getPendingSongs() {
    return this.store.select(getPendingSongs());
  }

  getNowPlaying() {
    return this.store.select(getNowPlaying());
  }

  getLastUpdatedUserPlaylist() {
    return this.store.select(getLastUpdatedUserPlaylist());
  }

  isSingleSongBusy() {
    return this.store.select(isSingleSongBusy());
  }

  getBackgroundColor() {
    return this.store.select(getBackgroundColor());
  }

  isBusy() {
    return this.store.select(isBusy());
  }
}
