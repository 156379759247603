import {Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {ControlValueAccessor, DefaultValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {MatAutocompleteSelectedEvent, MatAutocompleteTrigger} from '@angular/material/autocomplete';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteComponent),
      multi: true
    }
  ]
})

export class AutocompleteComponent implements OnInit, OnDestroy, ControlValueAccessor {

  @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;
  @ViewChild(DefaultValueAccessor) valueAccessor: DefaultValueAccessor;
  @Input() items: string[] = [];
  @Input() placeholder = '';
  @Output() onChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() onSubmit: EventEmitter<string> = new EventEmitter<string>();
  public form: FormGroup;
  private subs: Subscription[] = [];

  constructor() {
  }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.form = new FormBuilder().group({
      input: new FormControl()
    });
    this.subs.push(this.form.valueChanges.pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(value => this.onInputChanged(value.input)));
  }

  onInputChanged(value: string) {
    value = value ? value.toLowerCase().trim().replace('\'', '') : value;
    this.onChange.emit(value);
  }


  onInputSubmit(value) {
    value = value ? value.toLowerCase().trim().replace('\'', '') : value;
    this.autocomplete.closePanel();
    this.onSubmit.emit(value);
  }

  writeValue(obj: any) {
    this.valueAccessor.writeValue(obj);
  }

  registerOnChange(fn: any) {
    this.valueAccessor.registerOnChange(fn);
  }

  registerOnTouched(fn: any) {
    this.valueAccessor.registerOnTouched(fn);
  }

  setDisabledState(isDisabled: boolean) {
    this.valueAccessor.setDisabledState(isDisabled);
  }

  ngOnDestroy(): void {
    if (this.subs) {
      this.subs.forEach(sub => sub.unsubscribe());
    }
  }
}
