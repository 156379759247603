import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-kp-horizontal',
  templateUrl: './kp-horizontal.component.html',
  styleUrls: ['./kp-horizontal.component.scss']
})
export class KpHorizontalComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
