<app-kp-dialog title="Add Songs to Playlist">
  <div class="playlist-dialog__container">
    <div class="playlist-dialog__text">Create a new playlist or add the songs to an existing one</div>

    <mat-form-field class="playlist-dialog__item" (click)="onPlaylistSelected(null)">
      <mat-label>New Playlist</mat-label>
      <input matInput #message [formControl]="newPlaylistControl" maxlength="50"
             placeholder="Enter a name for your new Awesome playlist">
      <mat-hint align="end">{{message.value.length}} / 50</mat-hint>
    </mat-form-field>

    <div *ngFor="let playlist of playlists" class="playlist-dialog__item" (click)="onPlaylistSelected(playlist)"
         [ngClass]="{'playlist-dialog__selected': playlist.id === selectedPlaylist?.id}">
      {{playlist.name}}
    </div>


    <mat-dialog-actions>
      <button class="mat-raised-button kp-cancel-btn" (click)="close()">Close</button>
      <button class="mat-raised-button kp-primary-btn" (click)="onSubmit()">Let's Roll</button>
    </mat-dialog-actions>
  </div>
</app-kp-dialog>
