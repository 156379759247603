<div class="homepage__container">
  <app-kp-content>
    <div *ngIf="isLibrary && featuredContentsLoaded && categories.length && !categories[0]?.items?.length">
      You didn't create any Playlists yet
    </div>
    <div *ngFor="let featuredContent of categories" class="homepage__strip">
      <app-homepage-strip *ngIf="featuredContent.items.length"
                          [featuredContent]="featuredContent"></app-homepage-strip>
    </div>
    <app-spinner *ngIf="!featuredContentsLoaded"></app-spinner>
  </app-kp-content>
</div>
