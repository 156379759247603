<app-kp-dialog [title]="'Import Playlist'">
  <div class="import-dialog__input">
    <mat-form-field>
      <input matInput #input placeholder="Youtube/Spotify Playlist Link">
    </mat-form-field>
  </div>

  <div *ngIf="isError" class="import-dialog__error">Invalid Link</div>
  <mat-dialog-actions>
    <button class="mat-raised-button kp-cancel-btn" (click)="close()">Close</button>
    <button class="mat-raised-button kp-primary-btn" (click)="onFetchClicked(input.value)">Import</button>
  </mat-dialog-actions>
</app-kp-dialog>
