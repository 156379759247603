import {Action, createFeatureSelector, createReducer, createSelector, on} from '@ngrx/store';
import * as HomepageActions from './homepage-actions';
import {FeaturedContentDto} from '../../shared/models/featured-content.dto';
import {PlaylistDto} from '../../shared/models/playlist.dto';
import {MediaItemDto} from '../../shared/models/media-item.dto';

export interface State {
  isBusy: boolean;
  categories: FeaturedContentDto[];
  recommendations: MediaItemDto[];
  recentlyPlayed: PlaylistDto[];
}

export const initialState: State = {
  isBusy: false,
  categories: [],
  recommendations: [],
  recentlyPlayed: [],
};

const HomepageReducer = createReducer(
  initialState,

  on(HomepageActions.setCategories, (state, {featuredContents}) => ({...state, categories: featuredContents})),
  on(HomepageActions.setRecommendations, (state, {mediaItems}) => ({...state, recommendations: mediaItems})),
  on(HomepageActions.setRecentlyPlayed, (state, {playlists}) => ({...state, recentlyPlayed: playlists})),
);

export function HomepageReducers(state: State | undefined, action: Action) {
  return HomepageReducer(state, action);
}

export const selectAppState = createFeatureSelector<State>('KP_Homepage');

export const GetCategories = (state: State) => state.categories;

export const GetRecommendations = (state: State) => state.recommendations;

export const GetRecentlyPlayed = (state: State) => state.recentlyPlayed;

export const getCategories = () => createSelector(
  selectAppState,
  GetCategories
);

export const getRecommendations = () => createSelector(
  selectAppState,
  GetRecommendations
);

export const getRecentlyPlayed = () => createSelector(
  selectAppState,
  GetRecentlyPlayed
);
