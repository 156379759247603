<div class="action-menu__container" appClickOutside (clickOutside)="onClickOutside()">
  <div class="action-menu__button" (click)="onOpenMenuClicked($event)">
    <div class="material-icons">more_vert</div>
  </div>
  <div class="action-menu__menu">
    <div *ngIf="isOpen" class="action-menu__body">
      <div *ngFor="let item of items" class="action-menu__item" (click)="onClick(item,$event)">{{item.label}}</div>
    </div>
  </div>
</div>
